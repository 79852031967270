import React from 'react';  // eslint-disable-line
import DatePicker from 'react-datepicker';
import moment from 'moment';

// helper functions

const joinClasses = (...classes) => (
    classes.filter((c) => Boolean(c)).join(' ')
);

const inArray = (items, item) => items.indexOf(item) >= 0;

const addOnce = (items, item) => inArray(items, item) ? items : [...items, item];

const decimalInputOnly = (event) => {
    const period = 46;
    //const comma = 44;
    //if (!(event.charCode >= 48 && event.charCode <= 57) &&!(event.charCode === period || event.charCode === comma)) {
    if (!(event.charCode >= 48 && event.charCode <= 57) && !(event.charCode === period)) {
        event.preventDefault();
    }
};


// generic components
const Submit = ({label, icon='plus', disabled=true, onClick, title=''}) => (
    <button className='btn btn-secondary' type='submit' disabled={disabled} onClick={onClick} title={title}>
        <span className={`icon icon-${icon}`}></span> {label}
    </button>
);

const Alert = ({children, type='info'}) => (
    <div className={`alert alert-${type} mt-3`}>{children}</div>
);


const Field = ({label, error, children}) => {
    const labelEl = label ? <label>{label}</label> : '';
    const errorEl = error ? <Alert type='danger'>{error}</Alert> : '';
    const className = joinClasses('form-group', error ? 'has-danger' : '');

    return (
        <div className={className}>
            {labelEl}
            {children}
            {errorEl}
        </div>
    );

};


const TextInput = ({id, name, onChange=() => {}, defaultValue, placeholder='', label='', error='', type='text'}) => {
    const className = joinClasses('form-control', error ? 'form-control-danger' : '');
    name = name || id;

    return (
        <Field label={label} error={error}>
            <input
                type={type}
                id={id}
                name={name}
                className={className}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onChange}
            />
        </Field>
    );
};

const Textarea = ({id, name, onChange, defaultValue, placeholder='', label='', error=''}) => {
    const className = joinClasses('form-control', error ? 'form-control-danger' : '');
    name = name ? name : id;
    return (
        <Field label={label} error={error}>
            <textarea id={id} name={name}
                className={className} placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onChange}
            ></textarea>
        </Field>
    );
};


const Select = ({id, name, onChange, choices, defaultValue='', label='', error='', placeholder='', showBlank=false}) => {
    name = name || id;
    if (showBlank) {
        choices = [{value: '', label: placeholder}, ...choices];
    } else {
        defaultValue = defaultValue || choices[0].value;
    }

    return (
        <Field label={label} error={error}>
            <div>
                <select name={name} id={id} className='form-control' defaultValue={defaultValue} onChange={onChange} onBlur={onChange}>
                    {choices.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
                </select>
            </div>
        </Field>
    );
};


const RadioButtonInline = ({ name, id, value, onChange, checked, label }) => {
    return (
        <div className='form-group form-check form-check-inline'>
            <input type='radio' name={name} id={id} value={value} onChange={onChange} checked={checked} />
            <label className='form-check-label' htmlFor={id}>{label}</label>
        </div>
    )
}

const USStateSelect = (props)  => {
    const stateChoices = [
        {value: 'AL', label: 'Alabama'}, {value: 'AK', label: 'Alaska'}, {value: 'AZ', label: 'Arizona'},
        {value: 'AR', label: 'Arkansas'}, {value: 'CA', label: 'California'}, {value: 'CO', label: 'Colorado'},
        {value: 'CT', label: 'Connecticut'}, {value: 'DE', label: 'Delaware'}, {value: 'DC', label: 'District of Columbia'},
        {value: 'FL', label: 'Florida'}, {value: 'GA', label: 'Georgia'}, {value: 'HI', label: 'Hawaii'},
        {value: 'ID', label: 'Idaho'}, {value: 'IL', label: 'Illinois'}, {value: 'IN', label: 'Indiana'},
        {value: 'IA', label: 'Iowa'}, {value: 'KS', label: 'Kansas'}, {value: 'KY', label: 'Kentucky'},
        {value: 'LA', label: 'Louisiana'}, {value: 'ME', label: 'Maine'}, {value: 'MD', label: 'Maryland'},
        {value: 'MA', label: 'Massachusetts'}, {value: 'MI', label: 'Michigan'}, {value: 'MN', label: 'Minnesota'},
        {value: 'MS', label: 'Mississippi'}, {value: 'MO', label: 'Missouri'}, {value: 'MT', label: 'Montana'},
        {value: 'NE', label: 'Nebraska'}, {value: 'NV', label: 'Nevada'}, {value: 'NH', label: 'New Hampshire'},
        {value: 'NJ', label: 'New Jersey'}, {value: 'NM', label: 'New Mexico'}, {value: 'NY', label: 'New York'},
        {value: 'NC', label: 'North Carolina'}, {value: 'ND', label: 'North Dakota'}, {value: 'OH', label: 'Ohio'},
        {value: 'OK', label: 'Oklahoma'}, {value: 'OR', label: 'Oregon'}, {value: 'PA', label: 'Pennsylvania'},
        {value: 'PR', label: 'Puerto Rico'},
        {value: 'RI', label: 'Rhode Island'}, {value: 'SC', label: 'South Carolina'}, {value: 'SD', label: 'South Dakota'},
        {value: 'TN', label: 'Tennessee'}, {value: 'TX', label: 'Texas'}, {value: 'UT', label: 'Utah'},
        {value: 'VT', label: 'Vermont'}, {value: 'VA', label: 'Virginia'}, {value: 'WA', label: 'Washington'},
        {value: 'WV', label: 'West Virginia'}, {value: 'WI', label: 'Wisconsin'}, {value: 'WY', label: 'Wyoming'}
    ];
    return <Select choices={stateChoices} {...props} />;
};


class FileInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedFile: ''};
    }

    fileSelected(e) {
        const name = e.target.files[0] ? e.target.files[0].name : '';
        this.setState({selectedFile: name});
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    onBlur(e) {
        if (!e.target.value) {
            this.props.onChange(e);
        }
    }

    render() {
        let {id, name, label='', error=''} = this.props;
        name = name ? name : id;

        const selectText = this.state.selectedFile ? (
            this.state.selectedFile
        ): 'Choose File...';

        const selectTextStyle = {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: '6.2em',
            color: this.state.selectedFile ? '#333' : '#999'
        };

        /*
        return (
            <Field label={label} error={error}>
                <div>
                    <label className="file">
                        <input name={name} id={id} type="file" onChange={this.fileSelected.bind(this)} onBlur={this.onBlur.bind(this)} />
                        <span className="file-custom">
                            <div style={selectTextStyle}>{selectText}</div>
                        </span>
                    </label>
                </div>
            </Field>
        );
        */
        const requiredText = this.props.required && !this.state.selectedFile ? '(required)' : '';
        return (
            <Field label={label} error={error}>
                <div>
                    <input
                        type="file"
                        name={name}
                        id={id}
                        required={this.props.required}
                        accept=".doc,.docx,.pdf,.png,.jpg,.jpeg"
                        onBlur={this.onBlur.bind(this)}
                        onChange={this.fileSelected.bind(this)}
                    />
                    <p className='mt-3'>{requiredText}</p>
                </div>
            </Field>
        );
    }
}


// app components

const GrantUnits = (props) => {
    const {
        type, onChange, defaultValue, value, placeholders, readOnly,
        donationLabel='Amount',
        volunteerLabel='Total Hours',
        name='units',
        error='',
    } = props;

    let input = '',
        label = '',
        input_id = `id_${name}`;

    const inputClassName = joinClasses('form-control', error ? 'form-control-danger' : '');

    if (type === 'donation' || type === 'payroll') {
        label = donationLabel;
        input = (
            <div className="input-group">
                <span className="input-group-addon" id="{name}-addon">$</span>
                <input
                    type="text"
                    id={input_id}
                    name={name}
                    onKeyPress={decimalInputOnly}
                    className={inputClassName}
                    defaultValue={defaultValue}
                    value={value}
                    placeholder={`Enter amount: ${placeholders.donation}`}
                    onChange={onChange}
                    onBlur={onChange}
                    readOnly={readOnly}
                    aria-describedby="{name}-addon"
                />
            </div>
        );

    } else if (type === 'volunteer') {
        label = volunteerLabel;
        input = (
            <div className="input-group">
                <input
                    type="number"
                    id={input_id}
                    name={name}
                    className={inputClassName}
                    onKeyPress={decimalInputOnly}
                    placeholder={`Enter amount: ${placeholders.volunteer}`}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                    onBlur={onChange}
                    readOnly={readOnly}
                    aria-describedby="{name}-addon"
                />
                <span className="input-group-addon" id="{name}-addon">Hours</span>
            </div>
        );
    }

    return (
        <Field label={label} error={error}>
            {input}
        </Field>
    );
};


class DateButton extends React.Component {
    render() {
        const {onClick, value, name, id} = this.props;
        const className = `btn btn-${value ? 'secondary' : 'warning'}`;
        return (
            <div>
                <button type='button' className={className} onClick={onClick} style={{padding: "1em 1.75em"}}>
                    <span className='icon icon-calendar'></span>{' '}
                    {value || "Click to select date"}
                </button>
                <input type='hidden' value={value} name={name} id={id} />
            </div>
        );
    }
}


class GrantDate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: this.props.defaultValue ? moment(this.props.defaultValue, "MM/DD/YYYY") : null
        };
    }

    onChange(date) {
        this.setState({selectedDate: date});
        this.props.onChange(date);
    }

    onBlur(e) {
        if (!e.target.value) {
            this.props.onChange(null);
        }
    }

    getMinDate() {
        if (this.props.earliest) {
            const earliest = moment(this.props.earliest);
            if (earliest.isValid()) {
                return earliest;
            }
        }

        return null;
    }

    getMaxDate() {
        const now = moment();

        if (this.props.latest) {
            const latest = moment(this.props.latest);
            if (latest.isValid() && latest < now) {
                return latest;
            }
        }

        // default to today
        return now;
    }

    render() {
        const label = this.props.forType === 'volunteer' ? 'Most Recent Volunteer Date' : 'Date of Your Donation';

        const minDate = this.getMinDate();
        const maxDate = this.getMaxDate();

        // if minDate, maxDate, and defaultDate are the same, just show a hidden input
        const noChoice = minDate.isSame(maxDate, 'day') && minDate.isSame(this.state.selectedDate, 'day');

        // also hidden input for payroll
        const isPayroll = this.props.forType === 'payroll';

        if (noChoice || isPayroll) {
            return <input type='hidden' value={this.props.defaultValue} id='id_gift_date' name='gift_date' />;
        }

        return (
            <Field label={label} error={this.props.error}>
                <DatePicker
                    customInput={<DateButton />}
                    selected={this.state.selectedDate}
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    className='form-control'
                    placeholderText="mm/dd/yyyy (required)"
                    dateFormat="MM/DD/YYYY"
                    minDate={minDate}
                    maxDate={maxDate}
                    required={true}
                    id='id_gift_date'
                    name='gift_date'
                />
            </Field>
        );
    }
}


class Form extends React.Component {
    // props.postValidate = ((isValid) => undefined)
    constructor(props) {
        super(props);
        const initialData = this.props.initialData || {};
        const options = initialData.options || {};
        const initialKeys = Object.keys(initialData.data || {});
        const initialValidation = initialData.validation || {};
        const initialGrantType = (initialData.data || {}).type || options.types[0].value;
        this.state = {
            validation: {
                units: false,
                gift_date: false,
                receipt: !this.requireReceipt(initialGrantType, initialData),
                charity_name: false,
                charity_address1: false,
                charity_city: false,
                charity_state: false,
                charity_zip: false,
                ...initialValidation
            },
            dirty: initialKeys,
            grantType: initialGrantType,
            matchFullAmount: true,
            originalAmount: '',
            amountRequested: '',
            terms: false,
            submitting: false
        };
    }

    onSubmit(e) {
        this.setState({submitting: true});
        document.getElementById('new-submission-form').submit();
    }

    grantTypeChanged(event) {
        const grantType = event.target.value;

        // b/c we may only conditionally require receipt per-grant type
        // we need to update our grant type validation here.
        // this feels....wrong, but i'm not sure of a better way.
        let receiptIsValid = false;
        const requireReceipt = this.requireReceipt(grantType);
        const receiptField = this.refs.receipt;
        if (requireReceipt && receiptField) {
            receiptIsValid = Boolean(receiptField.state.selectedFile);
        } else {
            receiptIsValid = true;
        }

        const validation = {
            ...this.state.validation,
            receipt: receiptIsValid
        };

        // there's probably a better way to do this...but that's our app motto by now right?
        if (grantType == 'payroll') {
            validation.gift_date = true;
        }

        this.setState({grantType, validation});
    }

    grantUnitsChanged(event, name='units', enforceMins=true) {
        let valid = true;
        const value = event.target.value;
        const dirty = addOnce(this.state.dirty, name);

        // whether they're hours or money, we're going to validate as decimals with up two points...
        if (!value) {
            valid = false;
        } else {
            // our input only allows points and numbers, so we only need to validate the points,
            // so let's make sure there are 0 or 1 points with at most two numbers after it
            const splitValue = value.split('.');
            if (splitValue) {
                // ugh, i'm sorry
                if (splitValue.length > 2) {
                    valid = false;
                } else {
                    const cents = splitValue[1];
                    if (cents && (cents.length > 2 || cents.replace(/[0-9]*/, ''))) {
                        valid = false;
                    }
                }
            }

            // oh it just keeps getting worse: enforce minimums
            if (enforceMins) {
                const options = this.props.initialData.options;
                const volunteerMin = parseFloat(options.unit_rules.volunteer.min);
                const donationMin = parseFloat(options.unit_rules.donation.min);
                const payrollMin = parseFloat(options.unit_rules.payroll.min);
                if (this.state.grantType === 'volunteer') {
                    if (parseFloat(value) < volunteerMin) {
                        valid = false;
                    }
                } else if (this.state.grantType === 'donation') {
                    if (parseFloat(value) < donationMin) {
                        valid = false;
                    }
                } else if (this.state.grantType === 'payroll') {
                    if (parseFloat(value) < donationMin) {
                        valid = false;
                    }
                }
            }
        }

        this.setState({
            validation: {...this.state.validation, ...{[name]: valid}},
            dirty: dirty
        });
    }

    grantFullUnitsChanged(event) {
        this.grantUnitsChanged(event, 'full_units', false);
    }

    fieldUpdated(field, isValid) {
        const validation = {...this.state.validation, [field]: isValid};
        const dirty = addOnce(this.state.dirty, field);
        this.setState({validation, dirty});
    }

    requiredFieldChanged(field, event) {
        const isValid = Boolean(event.target.value);
        this.fieldUpdated(field, isValid);
    }

    requireReceipt(grantType, initialData) {
        grantType = grantType || this.state.grantType;
        initialData = initialData || this.props.initialData;
        if (grantType === 'donation') {
            return initialData.options.unit_rules.donation.require_receipt;
        } else if (grantType === 'volunteer') {
            return initialData.options.unit_rules.volunteer.require_receipt;
        } else {
            return false;
        }
    }

    grantDateChanged(date) {
        this.fieldUpdated('gift_date', moment(date).isValid());
    }

    render() {
        const MSG_REQUIRED = "This field is required.";

        const initialFormData = this.props.initialData.data;
        const options = this.props.initialData.options;
        const unitRules = options.unit_rules;

        const errors = Object
            .keys(this.state.validation)
            .filter((key) => !this.state.validation[key]);

        const hasErrors = errors.length > 0;
        const showErrorsFor = errors.filter((name) => inArray(errors, name) && inArray(this.state.dirty, name));

        const grantTypeChoices = options.types || [
            {value: 'donation', label: 'Donation'},
            {value: 'volunteer', label: 'Volunteer Hours'}
        ];

        const selectType = (grantTypeChoices.length === 1) ? (
            <input type='hidden' id='id_type' name='type' value={grantTypeChoices[0].value} />
        ) : (
            <Select
                id='id_type'
                name='type'
                label='Type of Gift'
                choices={grantTypeChoices}
                defaultValue={initialFormData.type}
                onChange={this.grantTypeChanged.bind(this)}
             />
        );

        const requireReceipt = this.requireReceipt();


        const isVolunteerAndTrackingOnly = this.state.grantType === 'volunteer' && !unitRules.volunteer.rate;
        const isDonationAndTrackingOnly = this.state.grantType === 'donation' && !unitRules.donation.ratio;
        const isPayrollAndTrackingOnly = this.state.grantType === 'payroll' && !unitRules.payroll.ratio;
        const isTrackingOnly = isVolunteerAndTrackingOnly || isDonationAndTrackingOnly;
        const showTermsAndPurpose = options.always_collect_purpose || !isTrackingOnly;

        let unitsHelpText = '';
        if (this.state.grantType === 'volunteer' && !isVolunteerAndTrackingOnly) {
            unitsHelpText = unitRules.volunteer.rate ? (
                <p className="form-text text-muted" style={{marginTop: '-0.5em'}}>
                    Hours entered here are matched at ${unitRules.volunteer.rate} per hour.
                </p>
            ) : '';
        } else if (this.state.grantType === 'donation' && !isDonationAndTrackingOnly) {
            unitsHelpText = (unitRules.donation.ratio && unitRules.donation.ratio !== "1-to-1 (100%)") ? (
                <p className="form-text text-muted" style={{marginTop: '-0.5em'}}>
                    The amount entered here will be automatically calculated at a ratio of {unitRules.donation.ratio} by your employer.
                </p>
            ) : '';
        } else if (this.state.grantType === 'payroll' && !isPayrollAndTrackingOnly) {
            unitsHelpText = (unitRules.payroll.ratio && unitRules.payroll.ratio !== "1-to-1 (100%)") ? (
                <p className="form-text text-muted" style={{marginTop: '-0.5em'}}>
                    The amount entered here will be automatically calculated at a ratio of {unitRules.payroll.ratio} by your employer.
                </p>
            ) : '';
        }

        const donationMin = unitRules.donation.min;
        const volunteerMin = unitRules.volunteer.min;
        const fullAmountCaveat = options.collect_full_amount ? " Must be equal to or less than full original amount" : "";
        const unitPlaceholders = {
            volunteer: volunteerMin ? `${volunteerMin} hour${volunteerMin === '1' ? '' : 's'} minimum (required)${fullAmountCaveat}` : `0 (required)${fullAmountCaveat}`,
            donation: donationMin ? `${donationMin} minimum (required)${fullAmountCaveat}` : `0.00 (required)${fullAmountCaveat}`
        };

        const fullUnitPlaceholders = {
            volunteer: "0 (required)",
            donation: "0.00 (required)",
        }

        const termsText = this.props.termsText || "To keep the IRS happy, in relation to this grant, I agree that every penny of this grant will be used for a charitable purpose, and neither I nor anyone I know will receive anything more than a coffee mug or any other incidental benefit in return. I also acknowledge that this grant will not fulfill a legally binding pledge or commitment.";
        const needTermsAgreement = isTrackingOnly ? false : !this.state.terms;

        const errorTip = hasErrors || needTermsAgreement
            ? <div className="alert alert-warning mt-3"><p><span className="icon icon-warning"></span> Please complete all required fields to submit.</p></div>
            : <div style={{height: 60, width: 1}}></div>;  // just to keep spacing the same

        return (
            <div>
                {selectType}

                {options.collect_full_amount && (

                    <div>
                        <GrantUnits
                            name='full_units'
                            type={this.state.grantType}
                            placeholders={{
                                donation: 'Enter the full amount of your original donation (required)',
                                volunteer: 'Enter the total hours you volunteered (required)',
                            }}
                            donationLabel="Amount of Original Donation"
                            volunteerLabel="Total Amount of Hours"
                            onChange={(e) => {
                                this.grantFullUnitsChanged(e);
                                this.setState({originalAmount: e.target.value});

                                if (this.state.matchFullAmount) {
                                    this.grantUnitsChanged(e)
                                };
                            }}
                            defaultValue={initialFormData.full_units}
                            value={this.state.originalAmount}
                            error={
                                inArray(showErrorsFor, 'full_units')
                                ? `${this.state.grantType === 'donation' ? '0.00' : '0'} (required)`
                                : ''}
                        />

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <RadioButtonInline
                                    name='matchAmountRadio'
                                    id='matchFull'
                                    onChange={() => {
                                        this.setState({
                                            matchFullAmount: true,
                                            originalAmount: '',
                                        });
                                    }}
                                    checked={this.state.matchFullAmount}
                                    label='Match the full amount of my donation'
                                />
                            </div>
                            <div className='col-12 col-md-6'>
                                <RadioButtonInline
                                    name='matchAmountRadio'
                                    id='matchPartial'
                                    onChange={() => {
                                        this.setState({
                                            matchFullAmount: false,
                                            amountRequested: '',
                                        });
                                    }}
                                    checked={!this.state.matchFullAmount}
                                    label='Match only a portion of my donation'
                                />
                            </div>
                        </div>

                        <GrantUnits
                            name='units'
                            type={this.state.grantType}
                            placeholders={unitPlaceholders}
                            donationLabel="Amount Requested for Organization"
                            volunteerLabel={options.collect_full_amount
                                ? "Hours you want to match"
                                : "Total Hours"
                            }
                            onChange={(e) => {
                                this.grantUnitsChanged(e)
                                this.setState({amountRequested: e.target.value});
                            }}
                            defaultValue={initialFormData.units}
                            value={this.state.matchFullAmount ? this.state.originalAmount : this.state.amountRequested}
                            error={inArray(showErrorsFor, 'units') ? unitPlaceholders[this.state.grantType] || 'Invalid Amount' : ''}
                            readOnly={this.state.matchFullAmount}
                        />
                        {unitsHelpText}
                    </div>
                )}

                {!options.collect_full_amount && (
                    <div>
                        <GrantUnits
                            name='units'
                            type={this.state.grantType}
                            placeholders={unitPlaceholders}
                            donationLabel="Amount Requested for Organization"
                            volunteerLabel={options.collect_full_amount
                                ? "Hours you want to match"
                                : "Total Hours"
                            }
                            onChange={(e) => {
                                this.grantUnitsChanged(e);
                                this.setState({amountRequested: e.target.value});
                            }}
                            defaultValue={initialFormData.units}
                            value={this.state.amountRequested}
                            error={inArray(showErrorsFor, 'units') ? unitPlaceholders[this.state.grantType] || 'Invalid Amount' : ''}
                        />
                        {unitsHelpText}
                    </div>
                )}

                <GrantDate
                    error={inArray(showErrorsFor, 'gift_date') ? "You must pick a date." : ''}
                    defaultValue={initialFormData.gift_date}
                    earliest={options.gift_date.earliest}
                    latest={options.gift_date.latest}
                    onChange={this.grantDateChanged.bind(this)}
                    forType={this.state.grantType}
                />

                {requireReceipt ? (
                    <FileInput
                        id='id_receipt'
                        name='receipt'
                        label='Receipt'
                        ref='receipt'
                        helpText='Upload a photo of your receipt'
                        required={true}
                        onChange={this.requiredFieldChanged.bind(this, 'receipt')}
                        error={inArray(showErrorsFor, 'receipt') ? "You must attach a receipt. (Images, PDF, and Word documents allowed.)" : ''}
                    />
                ) : ''}

                <TextInput id='id_charity_name'
                           name='charity_name'
                           label='Charitable Organization'
                           placeholder='Charitable Organization Name (required)'
                           defaultValue={initialFormData.charity_name}
                           onChange={this.requiredFieldChanged.bind(this, 'charity_name')}
                           error={inArray(showErrorsFor, 'charity_name') ? MSG_REQUIRED : ''}
                />

                <TextInput id='id_charity_tax_id'
                           name='charity_tax_id'
                           placeholder="Tax ID (00-0000000, optional)"
                           defaultValue={initialFormData.charity_tax_id}
                />

                {/* address */}
                <TextInput id='id_charity_address1'
                           name='charity_address1'
                           placeholder="Address Line 1 (required)"
                           defaultValue={initialFormData.charity_address1}
                           onChange={this.requiredFieldChanged.bind(this, 'charity_address1')}
                           error={inArray(showErrorsFor, 'charity_address1') ? MSG_REQUIRED : ''}
                />

                <TextInput id='id_charity_address2'
                           name='charity_address2'
                           placeholder="Address Line 2 (optional)"
                           defaultValue={initialFormData.charity_address2}
                />

                <TextInput id='id_charity_city'
                           name='charity_city'
                           placeholder="City (required)"
                           defaultValue={initialFormData.charity_city}
                           onChange={this.requiredFieldChanged.bind(this, 'charity_city')}
                           error={inArray(showErrorsFor, 'charity_city') ? MSG_REQUIRED : ''}
                />

                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <USStateSelect id='id_charity_state'
                                       name='charity_state'
                                       placeholder="Select State... (required)"
                                       defaultValue={initialFormData.charity_state}
                                       showBlank={true}
                                       onChange={this.requiredFieldChanged.bind(this, 'charity_state')}
                                       error={inArray(showErrorsFor, 'charity_state') ? MSG_REQUIRED : ''}
                        />
                    </div>

                    <div className='col-12 col-md-6'>
                        <TextInput id='id_charity_zip'
                                   name='charity_zip'
                                   placeholder="Zip Code (required)"
                                   defaultValue={initialFormData.charity_zip}
                                   onChange={this.requiredFieldChanged.bind(this, 'charity_zip')}
                                   error={inArray(showErrorsFor, 'charity_zip') ? MSG_REQUIRED : ''}
                        />
                    </div>
                </div>

                {showTermsAndPurpose ? (
                    <span>
                        <Textarea id='id_purpose'
                                  name='purpose'
                                  label='Purpose/Special Instructions'
                                  placeholder='Message for recipient (optional) - this will appear on the check'
                                  onChange={e => this.fieldUpdated('purpose', e.target.value.length <= 200)}
                                  error={inArray(showErrorsFor, 'purpose') ? "200 characters maximum." : ''}
                                  defaultValue={initialFormData.purpose}
                        />

                        <div className='card mb-3'>
                            <div className='card-block'>
                                <label style={{display: 'block'}}>
                                    <input type="checkbox" checked={this.state.terms} onChange={function(e) {
                                        this.setState({terms: e.target.checked});
                                    }.bind(this)}/>

                                    <span style={{paddingLeft: 10, verticalAlign: 'middle'}}>
                                        I agree to the following:
                                    </span>
                                </label>
                                <blockquote className='blockquote' style={{fontSize: '1rem'}} dangerouslySetInnerHTML={{__html: termsText}} />
                            </div>
                        </div>
                    </span>
                ) : ''}

                <Submit
                    label="Create Request"
                    icon={this.state.submitting ? 'spinner icon-spin' : 'plus'}
                    onClick={this.onSubmit.bind(this)}
                    disabled={hasErrors || this.state.submitting || needTermsAgreement}
                    title="Please complete all required fields to submit"
                />

                {errorTip}
            </div>
        );
    }
}

class SubmissionApp extends React.Component {
    render() {
        return (
            <Form initialData={window.initialData} termsText={window.termsText}></Form>
        );
    }
}

export default SubmissionApp;
