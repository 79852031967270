import React from 'react';  // eslint-disable-line
import moment from 'moment';
import Icon from './icons';

window.moment = moment;

function formatDate(isoString) {
    return isoString ? moment(isoString).format('LL') : '';
}


function GrantStatusIcon({match}) {
    if (match.status.slug === 'denied') {
        return <Icon name='minus-circle' className='text-danger' />;
    } else if (match.status.slug === 'approved') {
        if (match.paid_on) {
            return <Icon name='check-circle' className='text-success' />;
        } else {
            return <Icon name='check-circle-o' className='text-success' />;
        }
    } else if (match.status.slug === 'canceled') {
        return <Icon name='times' className='text-danger' />;
    }

    // default for 'pending' and 'processing'
    return <Icon name='refresh' className='gray-icon' />;
}


export function formatMoney(value, roundDollar=false) {
    // handy to allow value to be string (i.e. "1000.50" or even "1,000"
    if (typeof(value) === "string") {
        value = parseFloat(value.replace(/,/g, ""), 2);
    }

    if (roundDollar) {
        return value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }
}


// thanks IE
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
export function stringEndsWith(subjectString, searchString, position) {
    if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
        position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.lastIndexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
};


function SubmissionSummary({submission}) {
    // We only want the last word of the label
    const splitLabel = submission.type.label.split(' ');
    const label = splitLabel[splitLabel.length - 1];
    const value = formatMoney(submission.units, stringEndsWith(submission.units, '.00'));
    const valuePrefix = submission.type.slug === 'volunteer' ? '' : '$';
    return (
        <div>
            <label>{label}</label>
            <div className='summary-value'>{valuePrefix}{value}</div>
        </div>
    );
}


function MatchSummary({match}) {
    const slug = match.status.slug;
    const hasMatchAmount = Boolean(parseInt(match.amount, 10));

    let value = '';

    // for tracking only matches with no amount, we show nothing
    if (match.tracking_only && !hasMatchAmount) {
        return <div></div>;

    } else if (slug === 'canceled') {
        value = <div>Canceled</div>;

    } else if (slug === 'denied') {
        value = <div>Denied</div>;

    } else if (slug === 'approved') {
        value = <div>${formatMoney(match.amount, stringEndsWith(match.amount, '.00'))}</div>;

    } else {
        value = <div className='summary-value-pending'>Pending</div>;

    }

    return (
        <div>
            <label>{match.label || 'Match'}</label>
            <div className='summary-value'>{value}</div>
        </div>
    );
}


class Grant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false, canceling: false};
    }

    toggleDetails() {
        this.setState({open: !this.state.open});
    }

    cancelGrant(e) {
        this.setState({canceling: true});
        if (confirm('Are you sure you want to cancel this request?')) {
            e.preventDefault();
            this.refs.cancel_form.submit();
        } else {
            e.preventDefault();
            this.setState({canceling: false});
        }
        return false;
    }

    render() {
        const { grant, grantor, canCloneGrants } = this.props;
        const approved = grant.match.status.slug === 'approved';
        const denied = grant.match.status.slug === 'denied';
        const isZeroMatch = parseInt(grant.match.amount, 10) == 0;  // match was explicitly set to 0

        const paidRow = (approved && !isZeroMatch) ? (
            <tr>
                <th>Payment:</th>
                <td>{grant.match.paid_on ? formatDate(grant.match.paid_on) : 'Pending'}</td>
            </tr>
        ) : null;

        const statusRow = grant.match.status ? (
            <tr>
                <th>Status:</th>
                <td>
                    {denied ? grant.match.comment : grant.match.status.label}
                </td>
            </tr>
        ) : null;

        const purposeRow = grant.purpose ? (
            <tr>
                <th>Purpose:</th>
                <td>{grant.purpose}</td>
            </tr>
        ) : null;

        // pending grants can still be canceled
        const canCancel = grant.match.status.slug === 'pending' && grant.grantor === grantor.id;
        const cancelForm = canCancel ? (
            <form action={`/gifts/${grantor.id}/${grant.id}/cancel/`} method='POST' ref='cancel_form' style={{display: 'inline'}}>
                <input type='hidden' name='csrfmiddlewaretoken' value={this.props.csrfToken} />
                <button type='submit' onClick={this.cancelGrant.bind(this)} className='btn btn-danger' disabled={this.state.canceling}>
                    <Icon name='times' /> Cancel Request
                </button>
            </form>
        ) : '';

        // only allow cloning accepted applications
        const cloneForm = (approved && canCloneGrants) ? (
            <a href={`/gifts/${grantor.id}/${grant.id}/clone/`} className='btn btn-secondary m-r-1' >
                <Icon name='clone' /> Clone Request
            </a>
        ) : '';

        const grantDetails = this.state.open ? (
            <div className='grant-details p-t-1'>
                <table className='table table-sm table-bordered'>
                    <tbody>
                        {paidRow}
                        {statusRow}
                        {purposeRow}
                        <tr>
                            <th>Submitted:</th>
                            <td>{formatDate(grant.submitted_date)} by {grant.employee}</td>
                        </tr>
                        {grant.submission.type.slug === 'payroll' ? <tr>
                            <th>Submitted by:</th>
                            <td>Payroll Deduction</td>
                        </tr> : null}
                    </tbody>
                </table>

                {cloneForm}
                {cancelForm}
            </div>
        ) : '';

        const title = grant.match.paid_on ? 'Paid' : grant.match.status.label;

        return (
            <div className='grant'>
                <div className='grant-overview' onClick={this.toggleDetails.bind(this)}>
                    <div className='gift-meta'>
                        {moment.utc(grant.gift_date).format('LL')}
                        {this.props.showEmployee ? ` – ${grant.employee}` : ''}
                    </div>
                    <h3>{grant.organization.name}</h3>
                    <div className='row'>
                            <div className='col-2 status-icon' title={title}>
                                <GrantStatusIcon match={grant.match} />
                            </div>
                        <div className='col-4 col-md-2 summary submission-summary'>
                            <SubmissionSummary submission={grant.submission} />
                        </div>
                        <div className='col-4 col-md-3 summary match-summary'>
                            <MatchSummary match={grant.match} />
                        </div>
                        <div className='col-2 toggle-details'>
                            <Icon name='info-circle' className={this.state.open ? '' : 'gray-icon'} />
                        </div>
                    </div>
                </div>
                {grantDetails}
            </div>
        );
    }
}

const uniq = (arr) => arr
    .sort()
    .filter(
        (item, pos, ary) => !pos || item != ary[pos - 1]
    );


function GrantList({grants, canCloneGrants, grantor, csrfToken}) {
    const names = uniq(grants.map(grant => grant.employee));
    const multiEmployees = names.length > 1;

    const grantList = grants.length > 0 ? (
        grants.map(grant => <Grant key={grant.id} grant={grant} grantor={grantor} canCloneGrants={canCloneGrants} csrfToken={csrfToken} showEmployee={multiEmployees} />)
    ) : (
        <div className='alert alert-warning'><p>No requests submitted yet!</p></div>
    );

    return (
        <div className='grant-list'>
            {grantList}
        </div>
    );
}

export default GrantList;
