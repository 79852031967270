import React from 'react';  // eslint-disable-line
import ReactDOM from 'react-dom';

import SubmissionApp from './submission/app';
import GrantList from './grant-list';


const submissionEl = document.getElementById("new-submission-app");
if (submissionEl) {
    ReactDOM.render(<SubmissionApp />, submissionEl);
}

const grantListEl = document.getElementById("grant-list");
if (grantListEl) {
    const canCloneGrants = window.CAN_CLONE_GRANTS || false;
    ReactDOM.render(
        <GrantList
            grants={window.GRANT_LIST || []}
            grantor={{id: window.GRANTOR_ID}}
            canCloneGrants={canCloneGrants}
            csrfToken={window.CSRF_TOKEN}
        />,
        grantListEl
    );
}
